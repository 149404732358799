import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Icon, LinkBox, Section, Image, Strong, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaFacebookSquare, FaGithub, FaYoutube, FaFacebook, FaInstagram, FaTwitterSquare } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"New perspective on scandinavian design"} />
			<meta property={"og:title"} content={"Hellstrom | scandinavian design"} />
			<meta property={"og:description"} content={"New perspective on scandinavian design"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-ogimage.png?v=2023-03-29T19:52:24.158Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-32.png?v=2023-03-29T20:04:58.334Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-152.png?v=2023-03-29T20:05:05.735Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-152.png?v=2023-03-29T20:05:05.735Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-152.png?v=2023-03-29T20:05:05.735Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-152.png?v=2023-03-29T20:05:05.735Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-271.png?v=2023-03-29T20:05:13.498Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section background="--color-darkL1" padding="50px 0 50px 0" quarkly-title="Footer-19">
			<Box display="flex" justify-content="space-between" border-color="#232a44" md-flex-direction="column">
				<Box
					display="flex"
					md-margin="0px 0px 15px 0px"
					sm-flex-direction="column"
					justify-content="flex-start"
					align-items="center"
					grid-gap="24px"
				>
					<Box display="flex" grid-gap="8px">
						<Text margin="0px 0px 0px 0px" color="white" font="--base">
							E-mail:
						</Text>
						<Link
							href="tel:+9877654321223"
							color="--light"
							text-decoration-line="initial"
							font="normal 300 16px/1.5 --fontFamily-sans"
							display="block"
							margin="0px 0px 0px 0px"
							hover-color="#a78bfa"
						>
							codewithshinde@gmail.com
						</Link>
					</Box>
				</Box>
				<Box display="flex" grid-template-columns="repeat(5, 1fr)" grid-gap="16px 24px" md-align-self="flex-start">
					<LinkBox href="https://www.facebook.com/profile.php?id=100079863332062" target="_blank">
						<Icon
							category="fa"
							icon={FaFacebookSquare}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaGithub}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaYoutube}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section sm-padding="8px 0 8px 0" quarkly-title="Header-3">
			<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="flex-start"
				flex-direction="row"
				width="30%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="70%"
				md-justify-content="flex-start"
			>
				<LinkBox flex-direction="row" href="/" display="flex" grid-gap="12px">
					<Image
						src="https://uploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18:11:53.248Z"
						display="block"
						width="80px"
						srcSet="https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
					<Text
						margin="0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="--lead"
						sm-margin="0px 0 0px 0"
						display="block"
					>
						Lightworks Services
					</Text>
				</LinkBox>
			</Box>
			<Components.QuarklycommunityKitMobileSidePanel
				menuPosition="full"
				breakpoint="lg"
				width="70%"
				sm-width="50%"
				md-width="50%"
				lg-width="30%"
				onloadShow={false}
			>
				<Override slot="Children" md-display="flex" />
				<Override
					slot="Content"
					padding="0px 0px 0px 0px"
					background="rgba(255, 255, 255, 0)"
					lg-background="#ffffff"
					lg-margin="0px 0px 0px 0px"
				/>
				<Override
					slot="Button Text"
					font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					text-transform="uppercase"
					letter-spacing="1px"
					sm-font="normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					sm-margin="0px 2px 0px 0px"
					lg-margin="0px 0px 0px 0px"
				/>
				<Override
					slot="Button Icon :closed"
					category="fi"
					icon={FiMenu}
					size="32px"
					padding="5px 7px 5px 7px"
					border-radius="50px"
				/>
				<Override
					slot="Button Icon"
					width="28px"
					height="28px"
					category="fi"
					icon={FiMenu}
					color="--dark"
					size="24px"
					lg-width="32px"
					lg-height="32px"
				/>
				<Override
					slot="Cross"
					lg-width="32px"
					lg-height="32px"
					size="32px"
					top="24px"
					right="24px"
				/>
				<Box
					align-items="center"
					lg-justify-content="center"
					lg-align-items="flex-start"
					justify-content="flex-end"
					display="flex"
					lg-flex-direction="column"
					lg-margin="0px auto 0px auto"
					lg-min-width="300px"
					lg-max-width="1280px"
					lg-width="90%"
					lg-padding="24px 0px 48px 0px"
				>
					<Box
						display="none"
						lg-width="100%"
						lg-margin="0px 0px 24px 0px"
						lg-display="flex"
						lg-padding="12px 0px 12px 0px"
					>
						<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
							<Image
								src="https://uploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18:11:53.248Z"
								display="block"
								lg-width="50px"
								srcSet="https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
							/>
							<Text
								margin="0"
								md-margin="0px 0 0px 0"
								text-align="left"
								font="--lead"
								sm-margin="0px 0 0px 0"
								display="block"
							>
								Lightworks Services
							</Text>
						</LinkBox>
					</Box>
					<Link href="#" color="#000000">
						About Us
					</Link>
					<Components.QuarklycommunityKitMenu
						display="flex"
						filterMode="exclude"
						filterPages="/index"
						grid-gap="8px"
						lg-flex-direction="column"
						lg-padding="6px 0px 6px 0px"
						lg-margin="0px 0px 24px 0px"
						align-items="center"
						flex-wrap="wrap"
						overflow-x="visible"
						overflow-y="visible"
						lg-align-items="flex-start"
					>
						<Override
							slot="link"
							color="--darkL2"
							hover-color="--primary"
							font="600 16px sans-serif"
							text-decoration-line="initial"
							transition="color 0.1s ease 0s"
							lg-font="--lead"
						/>
						<Override slot="item-active" border-width={0} />
						<Override slot="item" padding="6px 8px 6px 8px" />
						<Override slot="link-active" cursor="default" color="--primary" />
						<Override slot="link-about" />
					</Components.QuarklycommunityKitMenu>
					<Box
						width="25%"
						display="none"
						lg-width="100%"
						lg-flex-direction="column"
						lg-align-items="flex-start"
						lg-display="flex"
						justify-content="space-around"
						align-items="center"
						flex-wrap="wrap"
						lg-margin="0px 0px 0px 0px"
					>
						<Link
							font="--lead"
							padding="10px 0px 10px 0px"
							margin="0px 0px 6px 0px"
							href="tel:12345678"
							text-decoration-line="initial"
							color="--dark"
							lg-margin="0px 0px 24px 0px"
						>
							+1(234)567-89-00
						</Link>
						<SocialMedia
							instagram="https://instagram.com/instagram"
							margin="0px 0px 0px 0px"
							facebook="https://www.facebook.com/quarklyapp/"
							youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw"
							lg-display="flex"
							lg-grid-gap="12px"
						>
							<Override slot="link-twitter" margin="0px 0px 0px 5px">
								<div />
							</Override>
							<Override
								slot="link"
								border-radius="50%"
								background="transparent"
								hover-color="--light"
								display="flex"
								margin="0 5px 0 5px"
								padding="5x 5px 5px 5px"
								width="32px"
								height="32px"
								align-items="center"
								justify-content="center"
							/>
							<Override slot="icon" size="32px" border-radius="50px" color="--darkL2" />
							<Override slot="link-facebook" margin="0px 5px 0px 0px">
								<div />
							</Override>
						</SocialMedia>
					</Box>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Your Premier SaaS Solution for Business Empowerment{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 400 24px/1.5 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					At Lightworks Services, we provide a comprehensive platform equipped with a suite of powerful tools and services tailored to meet the diverse needs of modern businesses. From project management and collaboration to communication and data analytics, our platform empowers businesses to streamline workflows, reduce costs, and enhance overall efficiency.{"\n\n"}
				</Text>
			</Box>
			<Image
				src="https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
				srcSet="https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1606166325683-e6deb697d301?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
			/>
		</Section>
		<Components.Tab />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Products
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					With a focus on innovation, reliability, and customer satisfaction, we offer a range of services tailored to streamline operations, enhance visibility, and drive growth.{"\n\n"}
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20:28:49.401Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20%3A28%3A49.401Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20%3A28%3A49.401Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20%3A28%3A49.401Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20%3A28%3A49.401Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20%3A28%3A49.401Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20%3A28%3A49.401Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/billdesk.png?v=2024-05-07T20%3A28%3A49.401Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							<Strong>
								POS Software Solutions
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Streamline your business operations and enhance customer experiences with our cutting-edge POS software solutions. Whether you operate a retail store, restaurant, or service-based business, our customizable POS systems are designed to meet your specific requirements. From inventory management and sales tracking to customer relationship management (CRM) and reporting analytics, our POS solutions empower businesses to optimize efficiency and drive profitability.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=804&q=80"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Finance Dashboard
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Gain valuable insights into your business performance with our advanced data analytics capabilities. Track key metrics, visualize data trends, and make informed decisions to drive growth and profitability. Our platform empowers businesses to harness the power of data to optimize processes and drive strategic initiatives.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
						display="inline-block"
						background="rgba(0, 0, 0, 0) url() 0% 0% /auto repeat scroll padding-box"
						align-self="flex-start"
						order="0"
						flex="1 1 0%"
						position="static"
						srcSet="https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1620287341401-e2945a4b9daa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							<Strong>
								Job Marketing Services
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Connect with top talent and build a dynamic workforce with our job marketing services. Utilizing innovative strategies and targeted approaches, we help businesses attract, engage, and retain skilled professionals across various industries. From job postings and candidate screening to recruitment marketing and employer branding, we offer end-to-end solutions to support your hiring needs and drive organizational success.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1601972602237-8c79241e468b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							<Strong>
								Food App Product Selling
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Tap into the lucrative food delivery market and expand your reach with our food app product selling services. Whether you're a restaurant owner, food vendor, or culinary entrepreneur, our platform enables you to showcase your offerings, manage orders, and reach a wider audience of hungry customers. With user-friendly interfaces, secure payment gateways, and seamless integration, our food app solutions empower businesses to thrive in the digital age.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							<Strong>
								Website Development Services
							</Strong>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Establish a strong online presence and engage your target audience with our website development services. From responsive web design and e-commerce functionality to content management systems (CMS) and search engine optimization (SEO), we create custom websites that reflect your brand identity and drive conversions. Whether you need a corporate website, e-commerce platform, or landing page, our team of experienced developers delivers high-quality solutions tailored to your business objectives.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-5">
			<Text
				margin="0px 0px 90px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				How We Work For You
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
				flex-direction="column"
				align-self="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						align-items="center"
						justify-content="center"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							1
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						border-width="0 0 0 1px"
						border-style="solid"
						border-color="#c8ced7"
						padding="0px 0px 0px 20px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Proposal
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							Once they understand your needs, they will create a proposal outlining the scope of work, timeline, and cost.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 0 0"
					lg-padding="0px 25px 0 0"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
						position="relative"
						z-index="3"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							2
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 0px -20px"
						padding="0px 0px 0px 20px"
						border-color="#c8ced7"
						border-style="solid"
						border-width="0 0 0 1px"
					>
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Agreement
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
							padding="0px 0px 50px 0px"
						>
							If you agree to the proposal, you will sign a contract or agreement outlining the terms of the project.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box
						min-width="40px"
						min-height="40px"
						background="--color-darkL1"
						display="flex"
						align-items="center"
						justify-content="center"
						width="40px"
						height="40px"
						border-radius="50%"
						margin="0px 0px 30px 0px"
						color="--darkL2"
					>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" text-align="center">
							3
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Execution
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							The company will begin working on the project and keep you updated on progress.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Footer-4">
			<Box
				display="flex"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 50px 0px"
				md-padding="0 0px 30px 0px"
				lg-padding="0 0px 50px 0px"
				md-flex-direction="column"
				md-display="flex"
				md-grid-gap="36px"
			>
				<Box
					lg-margin="0px 0px 0px 0px"
					width="30%"
					display="flex"
					lg-width="30%"
					sm-width="20%"
					md-width="10%"
				>
					<LinkBox
						justify-content="center"
						border-radius="100%"
						width="150px"
						height="150px"
						align-items="center"
						display="flex"
						href="#"
					>
						<Image src="https://uploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18:11:53.248Z" display="block" srcSet="https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66020a541d712a0023321d43/images/LW%20logo1.jpg?v=2024-05-07T18%3A11%3A53.248Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw" />
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(4, 1fr)"
					grid-gap="36px 54px"
					lg-align-items="start"
					md-grid-template-columns="repeat(4, 1fr)"
					md-grid-gap="24px"
					sm-grid-template-columns="repeat(2, 1fr)"
					sm-grid-gap="36px 24px"
					sm-width="100%"
				>
					<Box
						lg-justify-content="start"
						lg-align-items="flex-start"
						justify-content="flex-start"
						display="grid"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						grid-gap="8px 0"
						align-content="start"
						sm-justify-content="start"
						md-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Solution
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							Marketing
						</Link>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							Analytics
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
						>
							Commerce
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							display="flex"
						>
							Insights
						</Link>
					</Box>
					<Box
						lg-justify-content="start"
						lg-align-items="flex-start"
						justify-content="flex-start"
						display="grid"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						grid-gap="8px 0"
						align-content="start"
						sm-justify-content="start"
						md-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Support
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							Pricing
						</Link>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							Documentation
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
						>
							Guides
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							display="flex"
						>
							API status
						</Link>
					</Box>
					<Box
						lg-justify-content="start"
						lg-align-items="flex-start"
						justify-content="flex-start"
						display="grid"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						grid-gap="8px 0"
						align-content="start"
						sm-justify-content="start"
						md-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Company
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							About
						</Link>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							Blog
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
						>
							Jobs
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							display="flex"
						>
							Press
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							display="flex"
						>
							Partners
						</Link>
					</Box>
					<Box
						lg-justify-content="start"
						lg-align-items="flex-start"
						justify-content="flex-start"
						display="grid"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						grid-gap="8px 0"
						align-content="start"
						sm-justify-content="start"
						md-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Legal
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							Claim
						</Link>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							Privacy
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
						>
							Terms
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				padding="50px 0px 50px 0px"
				display="grid"
				justify-content="space-between"
				grid-template-columns="repeat(2, 1fr)"
				align-items="center"
				border-color="--color-lightD2"
				border-width="1px 0 0 0"
				border-style="solid"
				md-grid-template-columns="1fr"
				md-padding="30px 0px 30px 0px"
			>
				<Box padding="0px 50px 0px 0px" md-padding="0px 0 0px 0px" md-margin="0px 0px 25px 0px">
					<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.2 --fontFamily-sans" sm-text-align="center">
						Subscribe to our newsletter
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="#5a5d64" sm-text-align="center">
						The latest news, articles, and resources, sent to your inbox weekly.
					</Text>
				</Box>
				<Components.QuarklycommunityKitNetlifyForm display="flex" justify-content="flex-end" md-justify-content="flex-start" sm-justify-content="center">
					<Box display="flex" lg-flex-wrap="no-wrap" sm-flex-wrap="wrap">
						<Input
							margin="0px 16px 0px 0px"
							padding="10px 16px 10px 16px"
							placeholder="Enter your e-mail"
							width="100%"
							font="normal 300 18px/1.5 --fontFamily-sans"
							max-width="386px"
							border-radius="8px"
							name="Mobile "
							type="tel"
							required
							lg-margin="0px 10px 0px 0px"
							sm-margin="0px 0 0 0px"
							sm-text-align="center"
						/>
						<Button
							padding="10px 24px 10px 24px"
							font="normal 400 18px/1.5 --fontFamily-sans"
							sm-margin="10px 0px 0px 0px"
							sm-width="100%"
							border-style="solid"
							focus-box-shadow="none"
							color="--light"
							background="#6d32ec"
							border-radius="8px"
							border-width="2px"
							border-color="#6d32ec"
							white-space="nowrap"
						>
							Subscribe
						</Button>
					</Box>
				</Components.QuarklycommunityKitNetlifyForm>
			</Box>
			<Box
				display="flex"
				justify-content="space-between"
				padding="50px 0px 0px 0px"
				border-width="1px 0 0 0"
				border-style="solid"
				border-color="--color-lightD2"
				md-padding="30px 0px 0px 0px"
				md-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="#5a5d64"
					md-margin="0px 0px 25px 0px"
					sm-text-align="center"
				>
					© 2024 Lightworks Services, Inc. All rights reserved.
				</Text>
				<Box
					display="grid"
					grid-template-columns="repeat(5, 1fr)"
					grid-gap="16px 24px"
					md-align-self="flex-start"
					sm-align-self="center"
				>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaFacebook}
							size="24px"
							color="#5a5d64"
							hover-color="#6d32ec"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaInstagram}
							size="24px"
							color="#5a5d64"
							hover-color="#6d32ec"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaTwitterSquare}
							size="24px"
							color="#5a5d64"
							hover-color="#6d32ec"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaGithub}
							size="24px"
							color="#5a5d64"
							hover-color="#6d32ec"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaYoutube}
							size="24px"
							color="#5a5d64"
							hover-color="#6d32ec"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});