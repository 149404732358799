export default {
	"pages": {
		"root": {
			"pageUrl": "root",
			"name": "root",
			"children": [
				"66020a541d712a0023321d61",
				"66020a541d712a0023321d65"
			],
			"id": "root"
		},
		"66020a541d712a0023321d61": {
			"id": "66020a541d712a0023321d61",
			"name": "404",
			"pageUrl": "404"
		},
		"66020a541d712a0023321d65": {
			"id": "66020a541d712a0023321d65",
			"name": "index",
			"pageUrl": "index",
			"seo": {
				"title": ""
			}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": ""
		},
		"seo": {
			"title": "Hellstrom | scandinavian design",
			"og:image": "https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-ogimage.png?v=2023-03-29T19:52:24.158Z",
			"og:title": "Hellstrom | scandinavian design",
			"favicon_32px": "https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-32.png?v=2023-03-29T20:04:58.334Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-271.png?v=2023-03-29T20:05:13.498Z",
			"background_win10": "#F2EFEA",
			"description": "New perspective on scandinavian design",
			"og:description": "New perspective on scandinavian design",
			"favicon_apple_152px": "https://uploads.quarkly.io/6309b04d5e5c6e0021560652/images/hellstrom-favicon-152.png?v=2023-03-29T20:05:05.735Z"
		}
	}
}